export interface Section {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: number | null;
  updated_by: number | null;
  company_id: number;
  name: string;
  visible_for_sug: boolean;
  visible_for_ems: boolean;
  section_key: string | null;
  default_region: string;
}
