import { Address, ModifiedByUser } from '@ems/api';

export interface Company {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  parent_id: number;
  customer_key: number;
  status: string;
  name: string;
  pseudonym: string;
  v_number: number;
  website: string;
  comment: string;
  phone: string;
  email: string;
  newsletter: number;
  key_account: any;
  business_relationship: string;
  show_branch_number: number;
  billing_details_id: number;
  visitingAddress: Address;
  mailingAddress: Address;
  billingAddress: Address;
  tags: any[];
  sections: BranchSection[];
  mediaRelations: any[];
  contactRelations: ContactRelation[];
  createdBy: ModifiedByUser;
  updatedBy: ModifiedByUser;
  parent: Parent;
  subsidiaries: Subsidiary[];
}

interface ContactRelation {
  id: number;
  company_id: number;
  contact_id: number;
  relation: string;
  created_at: string;
  updated_at: string;
  created_by: any;
  updated_by: any;
  company: {
    id: number;
    name: string;
    pseudonym: string;
    business_relationship: string;
    v_number: number;
    mailingAddress: Address;
  };
  contact: {
    id: number;
    salutation: string;
    title: string;
    first_name: string;
    last_name: string;
    position: string;
    employer: {
      id: number;
      name: string;
      pseudonym: string;
      business_relationship: string;
      v_number: number;
      mailingAddress: Address;
    };
    status: string;
  };
}

interface Parent {
  id: number;
  name: string;
  pseudonym: string;
  business_relationship: string;
  v_number: number | null;
  mailingAddress: Address;
}

export interface Subsidiary {
  id: number;
  name: string;
  pseudonym: string;
  business_relationship: string;
  v_number: number;
  mailingAddress: Address;
}

export interface BranchSection {
  id: number;
  created_at: string;
  updated_at: string;
  created_by: string | null;
  updated_by: string | null;
  company_id: number;
  name: string;
  createdBy: ModifiedByUser | null;
  updatedBy: ModifiedByUser | null;
}
