export interface CompanyPriceManagement {
  id: number;
  company: {
    id: number;
    name: string;
    pseudonym: string;
    business_relationship: string;
    v_number: number;
    mailingAddress: {
      city: string;
    },
    parent_id: number;
  },
  section: {
    id: number;
    name: string;
  }
  price_management_id: number;
}
